import {update} from "@/utils/update";

/**
 *
 * @param inputDOM  file
 */
export function controlDimensions(inputDOM){
//    控制图片尺寸
    let width;
    if (inputDOM) {
        var reader = new FileReader()
        reader.onload = function (event) {
            var base64 = event.target.result
            var img = document.createElement('img')
            img.src = base64
            img.onload = function () {
                console.log(width)
                width =  img.width
            }
        }
        reader.readAsDataURL(inputDOM)
    }
    console.log(width)
    return width
}
