<template>
    <div class="liveMeet" style=" margin: -15px -15px;">
      <a-spin :spinning="spinning">
      <div class="liveMeet-top">
        <div class="liveMeet-top-input" style="width: 15%">
          <div>年份:</div>
          <a-date-picker
              v-model="year"
              mode="year"
              v-decorator="['year']"
              placeholder="请输入"
              format="YYYY"
              class="input"
              :open="yearShowOne"
              @openChange="openChangeOne"
              @panelChange="panelChangeOne"/>
        </div>
        <div class="liveMeet-top-input" style="width: 25%">
          <div>状态:</div>
          <a-radio-group
              style="margin-left: 5px"
              v-model="radioValue"
              @change="onChange">
            <a-radio-button value="">
              全部
            </a-radio-button>
            <a-radio-button value="1">
              已发布
            </a-radio-button>
            <a-radio-button value="0">
              未发布
            </a-radio-button>
          </a-radio-group>
        </div>
        <div class="liveMeet-top-input" style="width: 15%">
          <div>标题:</div>
          <a-input
              @pressEnter="seachBtn"
              v-model="titleData"
              class="input"
              placeholder="请输入" />
        </div>
        <div class="liveMeet-top-input" style="width: 20%">
          <div>平台:</div>
          <a-select
              @change="domainCodeChange"
              class="input"
              v-model="domainCode"
              placeholder="请选择">
            <a-select-option
                v-for="select_S in yun_subordinate_select"
                :value="select_S.domain_code">
              {{select_S.name}}
            </a-select-option>
          </a-select>
        </div>
        <div class="liveMeet-top-input" style="width: 12%">
          <a-checkbox-group
              v-model="principal"
              style="margin-top: 5px"
              @change="checkboxChange">
              <a-checkbox value="1">
                仅看自己负责
              </a-checkbox>
          </a-checkbox-group>
        </div>
        <div class="liveMeet-top-input">
          <a-button
              @click="seachBtn"
              type="primary">查询</a-button>
          <a-button
              @click="resetBtn"
              style="margin-left: 10px">重置</a-button>
        </div>
      </div>
      <div class="liveMeet-table">
        <div class="liveMeet-table-div">
          <div
              @click="addBtn"
              class="liveMeet-table-div-div-one">
            <div class="liveMeet-table-div-div-one-font">
              +新增
            </div>
          </div>
          <div
              v-for="(list,index) in liveList"
              :key="index"
              class="liveMeet-table-div-div">
            <div class="box">
              <img :src="list.detailCoverUrl" alt="">
                <div class="top">
                    <div v-if="list.domainCode === 'yunicu'" class="quSty">
                      云ICU
                    </div>
                    <div v-if="list.domainCode === 'ie-learning'" class="quStyI">
                      IELearning
                    </div>
                    <div v-if="list.domainCode === 'yichimeeting'" class="quStyY">
                      医驰
                    </div>
                    <div v-if="list.domainCode === 'aphouse'" class="quStyA">
                      APnet
                    </div>
                    <div v-if="list.type === 'SINGLE'" class="quStyXH" >
                      普通小会
                    </div>
                    <div v-if="list.type === 'SERIES'" class="quStyX">
                      系列会
                    </div>
                    <div v-if="list.type === 'CONVENTION'" class="quStyD">
                      多会场
                    </div>
                </div>
                <div class="bottom">
                  <div style="width: 100%;
                       padding-left: 5px;
                       display: block;
                       text-overflow: ellipsis;
                       overflow: hidden; white-space: nowrap;">
                    负责人：{{list.leaderSales}} {{list.leaderOperate}}
                  </div>
                </div>

            </div>
            <div class="content">
              <div class="content-title">{{list.title}}</div>
              <div class="content-time">
                <div>{{list.displayTime}}</div>
                  <a-switch
                      v-if="!list.isTest && list.type !== 'CONVENTION'"
                      v-model="list.isShow === 1?true:false"
                      checked-children="已发布"
                      un-checked-children="未发布"
                      @click="switchChange(list.id,list.isShow === 1? 0 : 1)"
                      default-checked />
              </div>
              <div style="height: 10px"></div>
              <div class="content-btn">
                  <a @click="editBtn(list)" class="btn-one">编辑</a>
                  <span style="color: #e8e8e8">|</span>
                <a @click="setBtn(list.id,list)" class="btn-one">直播间</a><span style="color: #e8e8e8">|</span>
                <a @click="dataBtn(list.id)" class="btn-one">导出</a><span style="color: #e8e8e8">|</span>

                <!--  是yunicu平台的 且不是多会场的才可以推广  -->
                <a v-if="list.type !== 'CONVENTION' && list.domainCode === 'yunicu'" @click="promotions(list)" class="btn-one">推广</a>
                <a v-else style="pointer-events:none;color: #d7d5d5" class="btn-one">推广</a>

                <span style="color: #e8e8e8;">|</span>
                <a-dropdown>
                  <a class="ant-dropdown-link btn-one" @click="e => e.preventDefault()">
                    更多 <a-icon type="down" />
                  </a>
                  <a-menu slot="overlay">
                    <a-menu-item>
                      <a  @click="promotionBtn(list)">开场图</a>
                    </a-menu-item>
                    <a-menu-item>
                      <a>统计</a>
                    </a-menu-item>
                  </a-menu>
                </a-dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--          分页-->
      <div style="width: 100%;height: 50px;background-color: #f0f2f5;display: flex;justify-content: right;">
        <div style="margin-right: 20px">
          <a-pagination
              show-quick-jumper
              :current="page_no"
              @change="listChange"
              :total="listCount" />
        </div>
      </div>
  <!--------------------------------------------对话框------------------------------------>
      <!--   新增-->
      <a-drawer
          :get-container="false"
          title="新增"
          :width="680"
          :visible="visible"
          :body-style="{ paddingBottom: '80px' }"
          @close="resetForm"
      >
        <a-form-model
            ref="ruleForm"
            :model="form"
            :rules="rules"
            :label-col="labelCol_model"
            :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="标题" prop="title">
            <div style="display: flex">
              <a-input v-model="form.title" placeholder="请输入"/>
              <a @click="openSample('liveMeet_title')" style="width: 50px;margin-top: -5px;margin-left: 3px">示例图</a>
            </div>
          </a-form-model-item>
          <a-form-model-item ref="name" label="显示会议时间" prop="displayTime">
            <div style="display: flex">
              <a-input v-model="form.displayTime" placeholder="请输入"/>
              <a @click="openSample('liveMeet_displayTime')" style="width: 50px;margin-top: -5px;margin-left: 3px">示例图</a>
            </div>
          </a-form-model-item>
          <a-form-model-item prop="sortTime">
            <!--          <div style="display: flex;width: 100%;justify-content: space-between">-->
            <!--            <div>-->
            <template slot="label">
                <span>直播开始时间
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>{{tooltip}}</span>
                    </template>
                    <a-icon type="exclamation-circle" />
                  </a-tooltip></span>
            </template>

            <div style="display: flex">
              <a-date-picker
                  style="margin-left: 2px"
                  :allowClear="false"
                  placeholder="请选择"
                  @change="live_begin_time_change_add"
                  format="YYYY-MM-DD HH:mm:ss"
                  :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"/>
              <a @click="openSample('liveMeet_sortTime')" style="width: 50px;margin-top: -5px;margin-left: 3px">示例图</a>
            </div>
          </a-form-model-item>
          <a-form-model-item prop="coverUrl" label="列表封面图">
            <div style="display: flex;width: 500px">
              <div style="display: flex;width: 400px;">
                <div class="uploadImg">
                  <input
                      id="coverFoemId"
                      style="width: 100px;height: 100px;opacity: 0;"
                      accept="image/*"
                      type="file"
                      @change="uplistCover"
                      ref="Thumbnail" />
                </div>
                <div
                    class="upload_image">
                  <img
                      v-if="form.coverUrl"
                      alt=""
                      style="width: 100%;height:100%"
                      :src="form.coverUrl"
                  />
                </div>
              </div>
              <div style="margin-left: 1px;">
                <a @click="openSample('liveMeet_coverUrl')" style="width: 50px;margin-top: -5px;margin-left: -35px">示例图</a>
              </div>
            </div>
            <div style="color: #a1a1a1">注:尺寸1920*520，大小不超过2M</div>
          </a-form-model-item>
          <a-form-model-item  label="详情封面图">
            <div style="display: flex;width: 500px">
              <div style="display: flex;width: 400px;">
                <div class="uploadImg" >
                  <input
                      id="detailCoverUrlId"
                      style="width: 100%;height:100%;opacity: 0;"
                      accept="image/*"
                      type="file"
                      @change="upDetailsCover"
                      ref="detailCoverUrl" />
                </div>
                <div
                    class="upload_deyails">
                  <img
                      v-if="form.detailCoverUrl"
                      alt=""
                      style="width: 100%;height:100%"
                      :src="form.detailCoverUrl"
                  />
                </div>
              </div>
              <div style="margin-left: 1px;">
                <a @click="openSample('liveMeet_detailCoverUrl')" style="width: 50px;margin-top: -5px;margin-left: -17px">示例图</a>
              </div>
            </div>
  <!--          <div style="color: #a1a1a1">注:尺寸640*360，大小不超过2M</div>-->
            <div style="color: #a1a1a1;width: 500px">注:建议尺寸1920px*1080px，宽度不能超过1920px，大小不超过2M</div>
          </a-form-model-item>
          <a-form-model-item  label="详情海报">
            <div style="display: flex;width: 700px">
              <div style="display: flex;width: 300px;">
                <div class="uploadImg" >
                  <input
                      id="descImageUrlId"
                      style="width: 100px;height: 100px;opacity: 0;"
                      accept="image/*"
                      type="file"
                      @change="upPosterCover"
                      ref="descImageUrl" />
                </div>
                <div
                    class="upload_posterH">
                  <img
                      v-if="form.descImageUrl"
                      alt=""
                      style="width: 100%;height:100%"
                      :src="form.descImageUrl"
                  />
                </div>
              </div>
              <div style="margin-left: 1px;">
                <a @click="openSample('liveMeet_descImageUrl')" style="width: 50px;margin-top: -5px;margin-left: -35px">示例图</a>
              </div>
            </div>
  <!--          <div style="color: #a1a1a1">注:尺寸宽640，大小不超过3M</div>-->
            <div style="color: #a1a1a1">注:宽度不能超过1920，大小不超过3M</div>
          </a-form-model-item>
          <a-form-model-item label="直播负责人" prop="leaderOperate">
            <a-select
                mode="multiple"
                show-search
                option-filter-prop="children"
                v-model="form.leaderOperate"
                placeholder="请选择"
                @change="ownershipChange">
              <a-select-option
                  v-for="select_o in PerList"
                  v-if="select_o.status !== 'INVALID'"
                  :value="select_o.userName">
                {{select_o.userName}}
              </a-select-option>
            </a-select>
            <div style="color: #a1a1a1">注:必须填写完整会议所有参与人</div>
          </a-form-model-item>

          <a-form-model-item label="会议类型" prop="type">
            <a-radio-group name="radioGroup" v-model="form.type">
              <a-radio value="SINGLE">
                普通小会
              </a-radio>
              <a-radio value="SERIES">
                系列会
              </a-radio>
              <a-radio value="CONVENTION">
                多会场
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="所属平台" prop="domainCode">
            <a-select
                v-model="form.domainCode"
                placeholder="请选择"
                @change="Subordinate_handleChange">
              <a-select-option
                  v-for="select_S in yun_subordinate"
                  :value="select_S.domain_code">
                {{select_S.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="所属栏目" prop="columnCode" v-if="form.domainCode === 'yunicu'">
            <a-select
                v-model="form.columnCode"
                placeholder="请选择"
                @change="ownershipChange">
              <a-select-option
                  v-for="select_o in ownershipList"
                  :value="select_o.code">
                {{select_o.title}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="业务负责人" prop="leaderSales">
            <a-select
                show-search
                option-filter-prop="children"
                v-model="form.leaderSales"
                placeholder="请选择"
                @change="ownershipChange">
              <a-select-option
                  v-for="select_o in PerList"
                  :value="select_o.userName">
                {{select_o.userName}}
              </a-select-option>
            </a-select>

          </a-form-model-item>
  <!--        <a-form-model-item label="关联医学会议" prop="conventionId" v-if="form.type === 'CONVENTION'">-->
  <!--          <a-select-->
  <!--              @popupScroll="popupScroll"-->
  <!--              @search="selectSearch"-->
  <!--              show-search-->
  <!--              option-filter-prop="children"-->
  <!--              v-model="form.conventionId"-->
  <!--              placeholder="请选择"-->
  <!--              @change="ownershipChange">-->
  <!--            <a-select-option-->
  <!--                v-for="select_convention in conventionList"-->
  <!--                :value="select_convention.id">-->
  <!--              {{select_convention.title}}-->
  <!--            </a-select-option>-->
  <!--          </a-select>-->
  <!--        </a-form-model-item>-->
        </a-form-model>
        <div
            :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button :style="{ marginRight: '8px' }" @click="resetForm">
            取消
          </a-button>
          <a-button type="primary" @click="onSubmit">
            确认
          </a-button>
        </div>
      </a-drawer>
      <!--   编辑 -->
      <a-drawer
          :get-container="false"
          title="编辑"
          :width="680"
          :visible="editVisible"
          :body-style="{ paddingBottom: '80px' }"
          @close="onEditClose"
      >
        <a-form-model
            ref="ruleEditForm"
            :model="editForm"
            :rules="rules"
            :label-col="labelCol_model"
            :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="标题" prop="title">
            <div style="display: flex">
              <a-input v-model="editForm.title" placeholder="请输入"/>
              <a @click="openSample('liveMeet_title')" style="width: 50px;margin-top: -5px;margin-left: 3px">示例图</a>
            </div>
          </a-form-model-item>
          <a-form-model-item label="显示会议时间" prop="displayTime">
            <div style="display: flex">
              <a-input v-model="editForm.displayTime" placeholder="请输入"/>
              <a @click="openSample('liveMeet_displayTime')" style="width: 50px;margin-top: -5px;margin-left: 3px">示例图</a>
            </div>
          </a-form-model-item>
          <a-form-model-item prop="sortTime">
  <!--          <div style="display: flex;width: 100%;justify-content: space-between">-->
  <!--            <div>-->
            <template slot="label">
                <span>直播开始时间
                <a-tooltip placement="top">
              <template slot="title">
                <span>{{tooltip}}</span>
              </template>
              <a-icon type="exclamation-circle" />
            </a-tooltip></span>
            </template>
            <div style="display: flex">
              <a-date-picker
                  style="margin-left: 2px"
                  :allowClear="false"
                  v-model="editForm.sortTime"
                  placeholder="请选择"
                  @change="live_begin_time_change_rest"
                  format="YYYY-MM-DD HH:mm:ss"
                  :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"/>
              <a @click="openSample('liveMeet_sortTime')" style="width: 50px;margin-top: -5px;margin-left: 3px">示例图</a>
            </div>
          </a-form-model-item>
          <a-form-model-item  label="列表封面图" prop="coverUrl">
            <div style="display: flex;width: 500px">
              <div style="display: flex;width: 400px;">
                <div class="uploadImg" >
                  <input
                      id="editThumbnailId"
                      style="width: 100px;height: 100px;opacity: 0;"
                      accept="image/*"
                      type="file"
                      @change="upEditlistCover"
                      ref="editThumbnail" />
                </div>
                <div
                    class="upload_image">
                  <img
                      v-if="editForm.coverUrl"
                      alt=""
                      style="width: 100%;height:100%;"
                      :src="editForm.coverUrl"
                  />
                </div>
              </div>
              <div style="margin-left: 1px">
                <a @click="openSample('liveMeet_coverUrl')" style="width: 50px;margin-top: -5px;margin-left: -35px">示例图</a>
              </div>
            </div>
            <div style="color: #a1a1a1">注:尺寸1920*520，大小不超过2M</div>
          </a-form-model-item>
          <a-form-model-item label="详情封面图">

            <div style="display: flex;width: 500px">
              <div style="display: flex;width: 400px;">
                <div class="uploadImg" >
                  <input
                      id="editDeyailsId"
                      style="width: 100px;height: 100px;opacity: 0;"
                      accept="image/*"
                      type="file"
                      @change="upEditDetailsCover"
                      ref="editDeyails" />
                </div>
                <div
                    class="upload_deyails">
                  <img
                      v-if="editForm.detailCoverUrl"
                      alt=""
                      style="width: 100%;height:100%"
                      :src="editForm.detailCoverUrl"
                  />
                </div>
              </div>
              <div style="margin-left: 1px;">
                <a @click="openSample('liveMeet_detailCoverUrl')" style="width: 50px;margin-top: -5px;margin-left: -17px">示例图</a>
              </div>
            </div>
  <!--          <div style="color: #a1a1a1">注:尺寸640*360，大小不超过2M</div>-->
            <div style="color: #a1a1a1;width: 500px">注:建议尺寸1920px*1080px，宽度不能超过1920px，大小不超过2M</div>
          </a-form-model-item>
          <a-form-model-item label="详情海报">

            <div style="display: flex;width: 500px">
              <div style="display: flex;width: 300px;">
                <div class="uploadImg" >
                  <input
                      id="editPosterId"
                      style="width: 100px;height: 100px;opacity: 0;"
                      accept="image/*"
                      type="file"
                      @change="upEditPosterCover"
                      ref="editPoster" />
                </div>
                <div
                    class="upload_posterH">
                  <img
                      v-if="editForm.descImageUrl"
                      alt=""
                      style="width: 100%;height:100%"
                      :src="editForm.descImageUrl"
                  />
                </div>
              </div>
              <div style="margin-left: 1px;">
                <a @click="openSample('liveMeet_descImageUrl')" style="width: 50px;margin-top: -5px;margin-left: -39px">示例图</a>
              </div>
            </div>
  <!--          <div style="color: #a1a1a1">注:尺寸宽640，大小不超过3M</div>-->
            <div style="color: #a1a1a1">注:宽度不能超过1920px，大小不超过3M</div>
          </a-form-model-item>
          <a-form-model-item label="直播负责人" prop="leaderOperate">
          <a-select
              mode="multiple"
              show-search
              option-filter-prop="children"
              v-model="editForm.leaderOperate"
              placeholder="请选择"
              @change="ownershipChange">
            <a-select-option
                v-for="select_o in PerList"
                v-if="select_o.status !== 'INVALID'"
                :value="select_o.userName">
              {{select_o.userName}}
            </a-select-option>
          </a-select>
            <div style="color: #a1a1a1">注:必须填写完整会议所有参与人</div>
        </a-form-model-item>
          <a-form-model-item label="会议类型" prop="type">
            <a-radio-group name="radioGroup" v-model="editForm.type">
              <a-radio value="SINGLE">
                普通小会
              </a-radio>
              <a-radio value="SERIES">
                系列会
              </a-radio>
              <a-radio value="CONVENTION">
                多会场
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="所属平台" prop="domainCode">
            <a-select
                disabled
                placeholder="请选择"
                v-model="editForm.domainCode"
                @change="Subordinate_handleChangeEdit">
              <a-select-option
                  v-for="select_r in yun_subordinate"
                  :value="select_r.domain_code">
                {{select_r.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="所属栏目" prop="columnCode" v-if="editForm.domainCode === 'yunicu'">
            <a-select
                v-model="editForm.columnCode"
                placeholder="请选择">
              <a-select-option
                  v-for="select_o in ownershipList"
                  :value="select_o.code">
                {{select_o.title}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="业务负责人" prop="leaderSales">
            <a-select
                show-search
                option-filter-prop="children"
                v-model="editForm.leaderSales"
                placeholder="请选择"
                @change="ownershipChange">
              <a-select-option
                  v-for="select_o in PerList"
                  :value="select_o.userName">
                {{select_o.userName}}
              </a-select-option>
            </a-select>
          </a-form-model-item>

        </a-form-model>
        <div
            :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button :style="{ marginRight: '8px' }" @click="onEditClose">
            取消
          </a-button>
          <a-button type="primary" @click="onEditSubmit">
            确认
          </a-button>
        </div>
      </a-drawer>
      <!--   开场图-->
      <a-drawer
          title="开场图"
          :width="600"
          :visible="promotion_visible"
          :body-style="{ paddingBottom: '80px' }"
          @close="()=>{this.promotion_visible = false;
            this.promotion_form.beginImageDetail = [];
            this.promotion_form.beginImageTime = null;
            // this.promotion_form.isBeginImage = null;
          }"
      >
        <a-form-model
            ref="promotion_ruleForm"
            :model="promotion_form"
            :rules="promotion_rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
        >
          <a-form-model-item  label="开场图">
            <a-switch
                v-model="promotion_form.isBeginImage === 1?true:false"
                checked-children="开"
                un-checked-children="关"
                @change="promotion_Switch_btn(promotion_form.isBeginImage === 0?1:0)"
                default-checked />
          </a-form-model-item>
          <a-form-model-item prop="beginImageDetail" label="选择图片">
            <div style="display: flex;width: 450px;">
              <div class="uploadImg" >
                <input
                    id="promotion_descImageUrlId"
                    style="width: 100px;height: 100px;opacity: 0;"
                    accept="image/*"
                    type="file"
                    @change="promotion_upPosterCover"
                    ref="promotion_descImageUrl" />
              </div>
              <div
                  class="upload_poster">
                <img
                    v-if="promotion_form.beginImageDetail"
                    alt=""
                    style="width: 100%;height:100%"
                    :src="promotion_form.beginImageDetail"
                />
              </div>
            </div>
            <div style="color: #a1a1a1">注:尺寸宽640，大小不超过1M</div>
          </a-form-model-item>
          <a-form-model-item label="时长" prop="beginImageTime">
            <a-input v-model.number="promotion_form.beginImageTime" placeholder="建议不超过5秒"/>
          </a-form-model-item>
  <!--        <a-form-model-item label="APP 首页广告">-->
  <!--          <a-switch-->
  <!--              v-model="promotion_form.isBannerShow === 1?true:false"-->
  <!--              checked-children="开"-->
  <!--              un-checked-children="关"-->
  <!--              @change="promotion_Switch_btn_openTwo(promotion_form.isBannerShow === 0?1:0)"-->
  <!--              default-checked />-->
  <!--        </a-form-model-item>-->
  <!--        <a-form-model-item label="APP个人中心">-->
  <!--          <a-switch-->
  <!--              :defaultChecked="promotion_form.openThree === 1?true:false"-->
  <!--              @change="promotion_Switch_btn_openThree(promotion_form.openThree === 0?1:0)"-->
  <!--              checked-children="开"-->
  <!--              un-checked-children="关"-->
  <!--              default-checked />-->
  <!--        </a-form-model-item>-->
        </a-form-model>
        <div
            :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button :style="{ marginRight: '8px' }" @click="()=>{
            this.promotion_visible = false;
            this.promotion_form.beginImageDetail = [];
            this.promotion_form.beginImageTime = null;
            this.promotion_form.isBeginImage = null;
            // this.promotion_form.isBannerShow = null;
          }">
            取消
          </a-button>
          <a-button type="primary" @click="promotion_Submit">
            确认
          </a-button>
        </div>
      </a-drawer>
      <!--   推广 -->
      <a-drawer
            title="推广"
            :width="500"
            :visible="promotionsVisible"
            :body-style="{ paddingBottom: '80px' }"
            @close="promotionsClose"
        >
        <div style="margin:20px">
          <a-spin :spinning="promotionSpin">
          <a-table
              :pagination="false"
              :columns="appColumns"
              :data-source="appData"
              :row-key="record => record.code"
              bordered>
            <span slot="is_open" slot-scope="text, record">
              <a-switch
                  v-model="record.is_open"
                  checked-children="是"
                  un-checked-children="否"
                  @click="AppListSwitch(record.is_open,record)"
                  default-checked />
            </span>
          </a-table>
          </a-spin>
        </div>
  <!--      <div style="margin:20px">-->
  <!--        <a-table :columns="communityColumns" :data-source="communityData" bordered>-->

  <!--        </a-table>-->
  <!--      </div>-->
          <div
              :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
          >
            <a-button :style="{ marginRight: '8px' }" @click="promotionsClose">
              关闭
            </a-button>
          </div>
        </a-drawer>
      <PublicSample :propList="propList"></PublicSample>
      </a-spin>
    </div>
  </template>

  <script>
  import {current_date} from "@/utils/currentDate";
  import {subordinate,subordinateList} from "@/utils/subordinateList"
  import {controlDimensions} from '@/utils/ControlDimensions'
  import {admin_icon} from '@/utils/myIcon_f'
  import moment from "moment";
  import {update} from "../../utils/update";
  import {difference} from '../../utils/timeDifference'
  import {
    getCommunictionList,
    getListContent, getLiveMeetList, getLiveTestList, getSetLiveList,
    LiveList_upOrDown,
    postLiveList,
    putLiveList, selCanJoinConvention
  } from "../../service/liveMeet_api";
  import {
    delAppPromotion,
    getAppPromotion, getColumnVodsList,
    postAppPromotion
  } from "@/service/MedicalConference_y";
  import PublicSample from "@/utils/PublicSample";
  const appColumns = [
    {
      title: 'APP首页',
      key: 'title',
      dataIndex: 'title',
    },
    {
      title: '状态',
      dataIndex: 'is_open',
      key: 'is_open',
      scopedSlots: { customRender: 'is_open' },
    },
  ];
  export default {
    name: "LiveMeet",
    components:{
      admin_con: admin_icon,
      PublicSample
    },
    data(){
      return{
        propList:{
          visible:false,
          code:null,
        },
        promontCount:false,
        liveHLinght:0,
        yearShowOne:false,
        promotionSpin:false,
        year:current_date(),
        domainCode:'',
        principal:['1'],
        principalName:'',
        isopen: false,
        spinning:false,
        editTime:[],
        listId:'',
        page_no:1,
        page_size:10,
        radioValue:'',
        imgUrl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.jj20.com%2Fup%2Fallimg%2F1113%2F032120114622%2F200321114622-4-1200.jpg&refer=http%3A%2F%2Fimg.jj20.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1652409503&t=82d54dc8933a1d4957146dddf466d4f3',
        listCount:null,
        visible:false,
        labelCol_model: { span: 5},
        labelCol: { span: 4},
        wrapperCol: { span: 14 },
        other: '',
        form: {
          title: '',
          displayTime:'',
          // dateRange:[],
          //所属平台
          domainCode:'yunicu',
          //直播开始时间
          sortTime:'',
          coverUrl:'https://yun-new-admin-1252497858.cos.ap-guangzhou.myqcloud.com/images/4df8b14d-a993-4f06-9c70-d1a3d50db4c8.jpg',
          detailCoverUrl:'https://yun-new-admin-1252497858.cos.ap-guangzhou.myqcloud.com/images/4df8b14d-a993-4f06-9c70-d1a3d50db4c8.jpg',
          descImageUrl:'https://yun-new-admin-1252497858.cos.ap-guangzhou.myqcloud.com/images/4df8b14d-a993-4f06-9c70-d1a3d50db4c8.jpg',
          //  所属栏目
          columnCode:'YUN_ICU',
          //运营负责人
          // leaderOperate:undefined,
          leaderOperate:[],
          //销售负责人
          leaderSales:undefined,
          // leaderSales:[],
          type:'SINGLE',
          // conventionId:undefined,
        },
        // conventionPageNo:1,
        // conventionTitle:'',
        ownershipList:[],
        rules:{},
        rules1: {
          title: [{ required: true, message: '请输入', trigger: 'blur' }],
          displayTime: [{ required: true, message: '请输入', trigger: 'blur' }],
          coverUrl: [{ required: true, message: '请上传', trigger: 'blur' }],
          domainCode: [{ required: true, message: '请选择', trigger: 'change' }],
          sortTime: [{ required: true, message: '请选择', trigger: 'change' }],
          columnCode: [{ required: true, message: '请选择', trigger: 'change' }],
          leaderSales: [{ required: true, message: '请选择', trigger: 'change' }],
          leaderOperate: [{ required: true, message: '请选择', trigger: 'change' }],
          type: [{ required: true, message: '请选择', trigger: 'change' }],
        },
        //columnCode   所属栏目  不必填
        rules2: {
          title: [{ required: true, message: '请输入', trigger: 'blur' }],
          displayTime: [{ required: true, message: '请输入', trigger: 'blur' }],
          coverUrl: [{ required: true, message: '请上传', trigger: 'blur' }],
          domainCode: [{ required: true, message: '请选择', trigger: 'change' }],
          sortTime: [{ required: true, message: '请选择', trigger: 'change' }],
          // columnCode: [{ required: true, message: '请选择', trigger: 'change' }],
          leaderSales: [{ required: true, message: '请选择', trigger: 'change' }],
          leaderOperate: [{ required: true, message: '请选择', trigger: 'change' }],
          type: [{ required: true, message: '请选择', trigger: 'change' }],
        },
        editForm: {
          title: '',
          displayTime:'',
          coverUrl:'',
          detailCoverUrl:'',
          descImageUrl:'',
          //所属平台
          domainCode:undefined,
          //直播开始时间
          sortTime:'',
          //  所属栏目
          columnCode:undefined,
          //运营负责人
          // leaderOperate:undefined,
          leaderOperate:[],
          //销售负责人
          leaderSales:undefined,
          // leaderSales:[],
          type:'SINGLE',
        },
        editVisible:false,
        liveList:[],
        liveTestList:[],
        titleData:'',
        timeList:[],
        promotion_visible:false,
        promotion_form:{
          isBeginImage:0,
          beginImageDetail:'',
          beginImageTime:null,
          // isBannerShow:0,
          // openThree:0,
        },
        promotion_rules: {
          beginImageDetail: [{ required: true, message: '请上传', trigger: 'blur' }],
          beginImageTime: [{ required: true, message: '请输入', trigger: 'blur' }],
        },
        cardId:'',
        isBeginImageVal:null,
        yun_subordinate_select:subordinate(),
        // yun_subordinate_select:[
        //   {
        //     id:1,
        //     name:'全部',
        //     domain_code:'',
        //   },
        //   {
        //     id:2,
        //     name:'医驰',
        //     domain_code:'yichimeeting',
        //   }, {
        //     id:3,
        //     name:'云ICU',
        //     domain_code:'yunicu',
        //   }, {
        //     id:4,
        //     name:'IE- Learning',
        //     domain_code:'ie-learning',
        //   },{
        //     id:5,
        //     name:'APnet',
        //     domain_code:'aphouse',
        //   },
        // ],
        yun_subordinate:subordinateList(),
        // yun_subordinate:[
        //   {
        //     id:1,
        //     name:'医驰',
        //     domain_code:'yichimeeting',
        //   }, {
        //     id:2,
        //     name:'云ICU',
        //     domain_code:'yunicu',
        //   }, {
        //     id:3,
        //     name:'IE- Learning',
        //     domain_code:'ie-learning',
        //   },{
        //     id:4,
        //     name:'APnet',
        //     domain_code:'aphouse',
        //   },
        // ],
        // yun_subordinate:[
        //   {
        //     id:1,
        //     name:'全部',
        //     domain_code:'',
        //   },
        //   {
        //     id:2,
        //     name:'汇医坊',
        //     domain_code:'huizhirenxin',
        //   }
        // ],
        promotionsVisible:false,
        appColumns,
        // communityColumns,
        appData:[],
        // communityData:[],
        columnListApp:[],
        promotionId:null,
        subContentTypes:false,
        tooltip:'用于会议列表页倒计时和排序',
        PerList:[],
        conventionList:[],
        // controlDimensions:controlDimensions()
      }
    },
    computed:{
      liveHistory(){
        return this.$store.state.liveMeetHistory
      }
    },
    created() {
      //1.获取本地存储内容
      const user_info = window.localStorage.getItem("user_info");
      const user_name =  JSON.parse(user_info).user_name
      //只有当菜单点击进入时获取用户的姓名
      if(this.liveHistory.isRouter){
        this.principalName = user_name
      }else {
        //历史操作
        this.historicalOperation()
      }

      this.form.leaderOperate = [user_name]
      // this.editForm.leaderOperate = [user_name]
      this.$store.dispatch('setManageHeaderTitle',"直播会议管理")
      this.getColumnLists()
      this.getLiveList()
    },
    methods:{
      openSample(code){
        // code加上时间防止重复
        this.propList = {
          visible:true,
          code:code+'202304031351',
        }
      },
      historicalOperation(){
        let form = this.liveHistory
        this.page_no = form.pageNo
        this.titleData = form.title
        this.radioValue = form.state
        this.year = form.year
        this.domainCode = form.code
        this.principalName = form.principal
        if(this.principalName){
          this.principal = ['1']
        }else {
          this.principal = []
        }
      },

      //获取直播会议列表  page_no,page_size,title,isShow,sortTime,domainCode,name
      async getLiveList() {
        this.spinning = true
        let data = {
          title:this.titleData,
          isShow:this.radioValue,
          sortTime:this.year,
          domainCode:this.domainCode,
          name:this.principalName,
          pageNo:this.page_no,
          pageSize:this.page_size
        }
        const response = await getLiveMeetList(data)
        if(response.code === 0){
          this.liveList = response.data.rows
          if(this.page_no === 1){
            //1.获取本地存储内容
            const user_info = window.localStorage.getItem("user_info");
            const userName =  JSON.parse(user_info).user_name
            const responses = await getLiveTestList(userName)
            let lenghtList = responses.data
            if(lenghtList.length){
              //向测试会议中添加isTest字段，用于判断是否是测试会议，测试会议不显示开关
              lenghtList.forEach((list)=>{
                list.isTest = 1
              })
              this.liveList.unshift(lenghtList[0])
            }
          }
          this.listCount = response.data.count
        }else {
          this.$message.warning(response.message)
        }
        this.spinning = false
      },
      //获取个人测试会议
      async getTestList() {
        const response = await getLiveTestList()
        if (response.code === 0) {
          this.liveTestList = response.data
        } else {
          this.$message.warning(response.message)
        }
      },
      // 弹出日历和关闭日历的回调
      openChangeOne(status) {
        if (status) {
          this.yearShowOne = true;
        }else {
          this.yearShowOne = false;
        }
      },
      // 得到年份选择器的值
      panelChangeOne(value) {
        this.yearShowOne = false;
        this.year = value;
        this.page_no = 1
        if(this.year){
          this.year = moment(this.year).format('YYYY')
          this.year = this.year + '-01-01 00:00:00'
        }
        // this.liveHistory.year = this.year
        this.getLiveList()
      },
      checkboxChange(checkedValues){
        // this.liveHistory.principal = checkedValues
        this.principal = checkedValues
        if(!checkedValues.length){
          this.principalName = ''
        }else {
          const user_info = window.localStorage.getItem("user_info");
          const user_name =  JSON.parse(user_info).user_name
          this.principalName = user_name
        }
        this.liveHistory.principal = this.principalName
        this.page_no = 1
        this.getLiveList()
      },
        moment,

      onChange(){
        // this.liveHistory.state = this.radioValue
        this.page_no = 1
        this.getLiveList()
      },
      //分页
      listChange(page_no){
        // this.liveHistory.pageNo = page_no

        this.page_no = page_no
        this.getLiveList()
      },
      async addBtn() {
        this.rules = this.rules1
        this.visible = true;

        // await this.getConventionList();
        //全部人员
        await this.getPerList()
      },
      showDrawer() {
        this.visible = true;
        // this.getConventionList()
      },
      onSubmit() {
        this.$refs.ruleForm.validate(async valid => {
          if (valid) {
            let form = this.form
            let data = {
              title: form.title,
              displayTime: form.displayTime,
              //所属平台
              domainCode: form.domainCode,
              //直播开始时间
              sortTime: form.sortTime,
              coverUrl: form.coverUrl,
              detailCoverUrl: form.detailCoverUrl,
              descImageUrl: form.descImageUrl,
              //  所属栏目
              columnCode: form.columnCode,
              //运营负责人
              leaderOperate: form.leaderOperate.toString(),
              //销售负责人
              leaderSales: form.leaderSales,
              //会议类型
              type: form.type,
              // //关联医学会议id
              // conventionId:form.conventionId
            }
            const response = await postLiveList(data)
            if(response.code === 0){
              this.$message.success("添加成功")
              this.visible = false
              //刷新列表
              await this.getLiveList()
              this.$refs.ruleForm.resetFields();
              this.form.leaderOperate = undefined
              // this.form.conventionId = undefined
              this.conventionList = []
              // this.conventionTitle = ''
              // this.conventionPageNo = 1
              this.PerList = []
            //  判断当普通小会新增成功后自定跳转到直播间页面
              if(this.form.type === 'SINGLE'){
                let id = response.data
                let row = await this.getOneLive(id)
                await this.setBtn(id,row)
              }
            }else {
              this.$message.warning("失败请联系管理员"+response.message)
            }
          } else {
            return false;
          }
        });
      },
      //获取单个会议
      async getOneLive(id) {
        const response = await getListContent(id)
        if(response.code === 0){
          return response.data
        }
      },
      resetForm() {
        this.$refs.ruleForm.resetFields();
        this.form.leaderOperate = undefined
        // this.form.conventionId = undefined
        this.conventionList = []
        // this.conventionTitle = ''
        // this.conventionPageNo = 1
        this.visible = false;
        this.PerList = []
      },
      async onEditSubmit() {
        this.$refs.ruleEditForm.validate(async valid => {
          if (valid) {
            let form = this.editForm
            let data = {
              title: form.title,
              displayTime: form.displayTime,
              coverUrl: form.coverUrl,
              detailCoverUrl: form.detailCoverUrl,
              descImageUrl: form.descImageUrl,
              //所属平台
              domainCode: form.domainCode,
              //直播开始时间
              sortTime: form.sortTime,
              //  所属栏目
              columnCode: form.columnCode,
              //运营负责人
              leaderOperate: form.leaderOperate.toString(),
              //销售负责人
              leaderSales: form.leaderSales,
              //会议类型
              type: form.type,
            }
            // if(data.leaderOperate){
            //   data.leaderOperate.toString()
            // }if(data.leaderSales){
            //   data.leaderSales.toString()
            // }
            const response = await putLiveList(this.listId, data)
            if (response.code === 0) {
              this.$message.success("修改成功！")
              //刷新列表
              await this.getLiveList()
              this.editVisible = false
              this.PerList = []
            } else {
              this.$message.warning("失败请联系管理员" + response.message)
            }
            this.editTime = []
          } else {
            this.$message.warning("表单有空，请先填写")
            return false;
          }
        });
      },
      onEditClose(){
        this.editVisible = false
        this.PerList = []
        this.$refs.ruleEditForm.resetFields()
        this.editForm.leaderOperate = undefined
        this.editTime = []
        this.getLiveList()
      },
      // resetEditForm() {
      //   this.editVisible = false;
      //   this.editTime = []
      // },
      // onClose(){
      //   this.visible = false
      //   this.$refs.ruleForm.resetFields()
      // },
      // 上传列表封面图
      async uplistCover() {
        let inputDOM = this.$refs.Thumbnail.files[0];
        //判断图片大小
        if (inputDOM.size < 2097152) {
          const image = await update(inputDOM)
          if (image.code === 0) {
            this.form.coverUrl = image.data.url
            this.$message.success("上传成功")
          } else {
            this.$message.warning("上传失败")
          }
        } else {
          this.$message.warning("请上传小于2M的图片！")
        }
        document.getElementById('coverFoemId').value = null;
      },
      upDetailsCover() {
        let form = this.form
        let _this = this
        let inputDOM = this.$refs.detailCoverUrl.files[0];
        //判断图片大小
        if (inputDOM.size < 2097152) {
          //判断图片尺寸
          if (inputDOM) {
            var reader = new FileReader()
            reader.onload = function (event) {
              var base64 = event.target.result
              var img = document.createElement('img')
              img.src = base64
              img.onload = async function () {
                if (img.width <= 1920) {
                  const image = await update(inputDOM)
                  if (image.code === 0) {
                    form.detailCoverUrl = image.data.url
                    _this.$message.success("上传成功")
                  } else {
                    _this.$message.warning(image.message)
                  }
                } else {
                  _this.$message.warning("建议尺寸1920px*1080px，宽度不能超过1920px")
                }
              }
            }
            reader.readAsDataURL(inputDOM)
          }
        } else {
          this.$message.warning("请上传小于2M的图片！")
        }
        document.getElementById('detailCoverUrlId').value = null;
      },
      async upPosterCover() {
        let form = this.form
        let _this = this
        let inputDOM = this.$refs.descImageUrl.files[0];
        //判断图片大小
        if (inputDOM.size < 3145728) {

          //判断图片尺寸
          if (inputDOM) {
            var reader = new FileReader()
            reader.onload = function (event) {
              var base64 = event.target.result
              var img = document.createElement('img')
              img.src = base64
              img.onload = async function () {
                if (img.width <= 1920) {
                  const image = await update(inputDOM)
                  if (image.code === 0) {
                    form.descImageUrl = image.data.url
                    _this.$message.success("上传成功")
                  } else {
                    _this.$message.warning(image.message)
                  }
                } else {
                  _this.$message.warning("注:宽度不能超过1920px !")
                }
              }
            }
            reader.readAsDataURL(inputDOM)
          }
        } else {
          this.$message.warning("请上传小于3M的图片！")
        }
        document.getElementById('descImageUrlId').value = null;
      },
      async promotion_upPosterCover() {
        let inputDOM = this.$refs.promotion_descImageUrl.files[0];
        //判断图片大小
        if (inputDOM.size < 1048576) {
          const image = await update(inputDOM)
          if (image.code === 0) {
            this.promotion_form.beginImageDetail = image.data.url
            this.$message.success("上传成功")
          } else {
            this.$message.warning("上传失败")
          }
        } else {
          this.$message.warning("请上传小于1M的图片！")
        }
        document.getElementById('promotion_descImageUrlId').value = null;
      },
      // 修改上传列表封面图
      async upEditlistCover() {
        let inputDOM = this.$refs.editThumbnail.files[0];
        //判断图片大小
        if (inputDOM.size < 2097152) {
          const image = await update(inputDOM)
          if (image.code === 0) {
            this.editForm.coverUrl = image.data.url
            this.$message.success("上传成功")
          } else {
            this.$message.warning("上传失败")
          }
        } else {
          this.$message.warning("请上传小于2M的图片！")
        }
        document.getElementById('editThumbnailId').value = null;
      },
      async upEditDetailsCover() {
        let form = this.editForm
        let _this = this
        let inputDOM = this.$refs.editDeyails.files[0];
        //判断图片大小
        if (inputDOM.size < 2097152) {

          //判断图片尺寸
          if (inputDOM) {
            var reader = new FileReader()
            reader.onload = function (event) {
              var base64 = event.target.result
              var img = document.createElement('img')
              img.src = base64
              img.onload = async function () {
                if (img.width <= 1920) {
                  // const image = await update(inputDOM)
                  // if (image.code === 0) {
                  //   form.descImageUrl = image.data.url
                  //   _this.$message.success("上传成功")
                  // } else {
                  //   _this.$message.warning(image.message)
                  // }
                  const image = await update(inputDOM)
                  if (image.code === 0) {
                    form.detailCoverUrl = image.data.url
                    _this.$message.success("上传成功")
                  } else {
                    _this.$message.warning(image.message)
                  }
                } else {
                  _this.$message.warning("注:建议尺寸1920px*1080px，宽度不能超过1920px !")
                }
              }
            }
            reader.readAsDataURL(inputDOM)
          }
        } else {
          this.$message.warning("请上传小于2M的图片！")
        }
        document.getElementById('editDeyailsId').value = null;
      },
      async upEditPosterCover() {
        let form = this.editForm
        let _this = this
        let inputDOM = this.$refs.editPoster.files[0];
        //判断图片大小
        if (inputDOM.size < 3145728) {
          //判断图片尺寸
          if (inputDOM) {
            var reader = new FileReader()
            reader.onload = function (event) {
              var base64 = event.target.result
              var img = document.createElement('img')
              img.src = base64
              img.onload = async function () {
                if (img.width <= 1920) {
                  const image = await update(inputDOM)
                  if (image.code === 0) {
                    form.descImageUrl = image.data.url
                    _this.$message.success("上传成功")
                  } else {
                    _this.$message.warning(image.message)
                  }
                } else {
                  _this.$message.warning("注:宽度不能超过1920px !")
                }
              }
            }
            reader.readAsDataURL(inputDOM)
          }
        } else {
          this.$message.warning("请上传小于3M的图片！")
        }
        document.getElementById('editPosterId').value = null;
      },
      async editBtn(row) {

        if(row.domainCode === 'yunicu'){
          this.rules = this.rules1
        }else {
          this.rules = this.rules2
        }
        //判断当前时间是不是大于要修改的时间
        // if(row.sortTime !== undefined && row.sortTime !== null && row.sortTime !== ''){
        //   let date1 = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
        // if(difference(date1,row.sortTime) <= 10){
        this.editVisible = true
        this.listId = row.id
        let form = this.editForm
        form.title = row.title
        form.displayTime = row.displayTime
        form.coverUrl = row.coverUrl
        form.detailCoverUrl = row.detailCoverUrl
        form.descImageUrl = row.descImageUrl
        form.domainCode = row.domainCode
        form.sortTime = row.sortTime
        form.columnCode = row.columnCode
        form.type = row.type
        if(row.leaderOperate){
          form.leaderOperate = row.leaderOperate.split(',')
        }
        if(row.leaderSales){
          form.leaderSales = row.leaderSales
        }
        //全部人员
        await this.getPerList()
        // await this.getConventionList()
      },
      async switchChange(id, isShow) {
        const data = {
          isShow:isShow
        }
        const response = await putLiveList(id, data)
        if (response.code === 0) {
          this.$message.success("修改成功！")
          this.editVisible = false
          this.PerList = []
        //  刷新列表
          await this.getLiveList()
        } else {
          this.$message.warning("失败请联系管理员" + response.message)
        }
      },
      seachBtn(){
        if(this.year){
          this.year = moment(this.year).format('YYYY')
          this.year = this.year + '-01-01 00:00:00'
        }
        this.page_no = 1
        this.getLiveList()
      },
      // titleInput(){
      //   this.liveHistory.title = this.titleData
      // },
      resetBtn(){
        this.titleData = ''
        this.radioValue = ''
        this.year = ''
        this.page_no = 1
        this.domainCode = undefined
        const user_info = window.localStorage.getItem("user_info");
        const user_name =  JSON.parse(user_info).user_name
        this.principalName = user_name
        this.principal = ['1'],
            this.getLiveList()
      },
      domainCodeChange(){
        // this.liveHistory.code = value
        if(this.year){
          this.year = moment(this.year).format('YYYY')
          this.year = this.year +'-01-01 00:00:00'
        }
        this.page_no = 1
        this.getLiveList()
      },
      setBtn(id,row){
        this.recordLive()
        localStorage.setItem('liveImg',JSON.stringify(row))
        //路由跳转
        this.$router.push({path:'/setliveliveMeet',query:{live_metting_id:id,type:row.type,conventionId:row.conventionId}})
      },
      //记录
      recordLive(){
        //记录操作步骤
        this.liveHistory.year = this.year
        this.liveHistory.state = this.radioValue
        this.liveHistory.title = this.titleData
        this.liveHistory.code = this.domainCode
        this.liveHistory.pageNo = this.page_no
        this.checkboxChange(this.principal)
      },
      dataBtn(id){
        //路由跳转
        this.$router.push({path:'/liveliveInformation',query:{live_metting_id:id}})
      },
      promotionBtn(list){
        // this.isBeginImageVal =
        this.cardId = list.id
        this.promotion_visible = true
        this.getLiveOneList(list.id)
      },
      //获取单个会议
      async getLiveOneList(id) {
        const response = await getListContent(id)
        if (response.code === 0) {
          let form = this.promotion_form
          this.promotion_form.isBeginImage = response.data.isBeginImage
          // this.promotion_form.isBannerShow = response.data.isBannerShow
          if(response.data.beginImageDetail !== null
              && response.data.beginImageDetail !== ''
              &&response.data.beginImageDetail !== undefined){
            this.promotion_form.beginImageDetail = response.data.beginImageDetail[0]
          }
          this.promotion_form.beginImageTime = response.data.beginImageTime

        } else {
          this.$message.warning("失败请联系管理员" + response.message)
        }
      },
      promotion_Submit() {
        this.$refs.promotion_ruleForm.validate(async valid => {
          if (valid) {
            let data = {
              "beginImageDetail": this.promotion_form.beginImageDetail.split(','),
              "beginImageTime": this.promotion_form.beginImageTime,
              "isBeginImage": this.promotion_form.isBeginImage,
              // "isBannerShow":this.promotion_form.isBannerShow
            }
            const response = await putLiveList(this.cardId, data)
            if (response.code === 0) {
              this.$message.success("修改成功！")
              this.promotion_visible = false
              this.promotion_form.beginImageDetail = [];
              this.promotion_form.beginImageTime = null;
              this.promotion_form.isBeginImage = null;
              // this.promotion_form.isBannerShow = null;
            } else {
              this.$message.warning("失败请联系管理员" + response.message)
            }
          } else {
            return false;
          }
        });
      },
      async promotion_Switch_btn(isOpen) {
        this.promotion_form.isBeginImage = isOpen
      },
      // async promotion_Switch_btn_openTwo(isOpen) {
        // this.promotion_form.isBannerShow = isOpen
      // },
      // promotion_Switch_btn_openThree(isOpen){
      //   this.promotion_form.openThree = isOpen
      // },
      //修改直播会议上移下移
      async getListUpDown(id, action) {
        const response = await LiveList_upOrDown(id, action)
        if(response.code === 0){
          this.$message.success("修改成功！")
        //  刷新列表
          await this.getLiveList()
        }else {
          this.$message.warning("失败"+response.message)
        }
      },
      upBtn(list){
        this.getListUpDown(list.id, 'up')
      },
      DownBtn(list){
        this.getListUpDown(list.id, 'down')
      },
      //新增中的所属平台
      Subordinate_handleChange(value){
        if(value === 'yunicu'){
          this.rules = this.rules1
          this.form.columnCode = 'YUN_ICU'
        }else {
          this.rules = this.rules2
          this.form.columnCode = ''
        }
      },
      //修改中的所属平台
      Subordinate_handleChangeEdit(value){
        if(value === 'yunicu'){
          this.rules = this.rules1
          this.editForm.columnCode = 'YUN_ICU'
        }else {
          this.rules = this.rules2
          this.editForm.columnCode = ''
        }
      },
      live_begin_time_change_add(date){
        this.form.sortTime = moment(date).format('YYYY-MM-DD HH:mm:ss')
      },
      live_begin_time_change_rest(date){
        this.editForm.sortTime = moment(date).format('YYYY-MM-DD HH:mm:ss')
      },
      async getAppList(id) {
        const response = await getAppPromotion(id)
        if (response.code === 0) {
          this.columnListApp = response.data
          //对比
          let app = this.appData
          let columnList = this.columnListApp
            app.forEach((a,index) =>{
              a.is_open = columnList.some(b =>{
                return b.code === a.code
              })
              this.$set(app,index,a)
            })
        } else {
          this.$message.warning(response.message)
        }
      },
      //获取栏目
      async getColumnList(id) {
        this.promotionSpin = true
        const response = await getColumnVodsList()
        if (response.code === 0) {
          this.appData = response.data
          await this.getAppList(id)
        } else {
          this.$message.warning(response.message)
        }
        this.promotionSpin = false
      },
      // async getCommunityList(id) {
      //   const response = await getcommunityPromotion(id)
      //   if (response.code === 0) {
      //     this.communityData = response.data
      //   } else {
      //     this.$message.warning(response.message)
      //   }
      // },
      async promotions(list) {
        // //子级内容根据判断直播间个数 =1：SINGLE   > 1: SERIES  医学会议：convention
        await this.getRoomLenght(list.id)

        //根据type判断推广时subContentType传值
        this.subContentTypes = list.type

        if (this.promontCount) {
          this.promotionId = list.id
          this.getColumnList(list.id)
          this.promotionsVisible = true
        } else {
          this.$message.warning("当前会议内没有直播间，请先添加直播间在进行推广~")
        }
      },
      promotionsClose(){
        this.promotionsVisible = false
        this.appData = []
        this.promotionId = null
      },
      //获取直播间个数
      async getRoomLenght(id) {
        let data = {
          liveMettingId: id
        }
        const response = await getSetLiveList(data)
        if(response.data.count === 0){
          this.promontCount = false
        }else {
          this.promontCount = true
        }
      },
      async getRoomLenghts(id) {
        let data = {
          liveMettingId: id
        }
        const response = await getSetLiveList(data)
        // this.getLiveH(data)
        // return this.liveHLinght
        if (response.data.count === 1) {
          return 'SINGLE'
        } else if (response.data.count > 1) {
          return 'SERIES'
        } else if (response.data.count === 0) {
          return '1'
        }
      },
      async getLiveH(data) {
        const response = await getSetLiveList(data)
        this.liveHLinght = response.data.count
      },
      async AppListSwitch(isOpen, row) {
          //推广
          if(isOpen){
            let data = [
              {
                contentId: this.promotionId,//内容id
                contentType: "LIVE_METTING",//一级内容
                columnCode: row.code,//栏目
                subContentType: this.subContentTypes//子级内容
              }
            ]
            await this.postAppSwitch(data,row)
          }else { //关闭推广
            await this.delAppSwitch(this.promotionId,row.code,'app',row)
          }
      },
      async delAppSwitch(id,code,type,row) {
        let data = {
          code:code,
          type:type
        }
        const response = await delAppPromotion(id,data)
        if (response.code === 0) {
          //更新数据
          this.appData.forEach((a,index) => {
            if (a.code === row.code) {
              this.$set(this.appData, index, a)
              return
            }
          })
          this.$message.success("关闭成功~")
        } else {
          this.$message.warning(response.message)
        }
      },
      async postAppSwitch(data,row) {
        const response = await postAppPromotion(data)
        if (response.code === 0) {
          //更新数据
          this.appData.forEach((a,index) => {
            if (a.code === row.code) {
              this.$set(this.appData, index, a)
              return
            }
          })
          this.$message.success("推广成功~")
        } else {
          this.$message.warning(response.message)
        }
      },
      async getColumnLists() {
        const response = await getColumnVodsList()
        if (response.code === 0) {
          this.ownershipList = response.data
        }
      },
      ownershipChange(value){
      },
      //获取公司人员列表
      async getPerList(departmentById) {
        //销售部 8994250
        const response = await getCommunictionList(1,9999,1,'',departmentById)
        if(response.code === 0){
          this.PerList = response.data.rows
        }
      },
    //  查询可关联的医学会议
    //   async getConventionList(title) {
    //     let data = {
    //       title:title,
    //       page_no:this.conventionPageNo,
    //       page_size:10,
    //     }
    //     const response = await selCanJoinConvention(data)
    //     if(response.code === 0){
    //       let list  = response.data
    //       for (let i=0;i<list.length;i++){
    //         this.conventionList.push({
    //           title: list[i].title,
    //           id: list[i].id,
    //         })
    //       }
    //     }
    //   },
      // //滚动条监听
      // popupScroll(e){
      //   const {target} = e
      //   const scrollHeight = target.scrollHeight - target.scrollTop //滑动总高度
      //   const clientHeight = target.clientHeight //滑动最低高度
      //   if(scrollHeight < clientHeight + 2){
      //     this.conventionPageNo++
      //     this.getConventionList(this.conventionTitle)
      //   }
      // },
      // selectSearch(value) {
      //   //先清空，防止鼠标滑动时添加的数据输入时添加的数据重复
      //   this.conventionList = []
      //   this.conventionTitle = value
      //   this.getConventionList(this.conventionTitle)
      // },
      // radioChange(){
      //   if(this.form.type === 'CONVENTION'){
      //     if(!this.rules.conventionId){
      //       let conventionId = [{ required: true, message: '请选择', trigger: 'change' }]
      //       this.rules.conventionId = conventionId
      //     }
      //   }else {
      //     if(this.rules.conventionId){
      //       this.form.conventionId = undefined
      //       delete this.rules.conventionId
      //     }
      //   }
      // }
    }
  }
  </script>

  <style scoped lang="scss">

  .liveMeet{
    height: auto;
    background-color: #F0F2F5;
    .liveMeet-top{
      width: 100%;
      height: 85px;
      background-color: #fff;
      border-bottom: 15px solid #F0F2F5;
      display: flex;
      justify-content: space-between;
      .liveMeet-top-input{
        display: flex;
        justify-content: center;
        height: 50px;
        line-height: 30px;
        padding-top: 20px;
        .input{
          width: 70%;
          margin-left: 10px;
        }
      }
    }
    .liveMeet-table{
      width: 100%;
      min-height: 700px;
      background-color: #f0f2f5;
      .table-clo{
        height: 300px;
        background-color: #fff;
      }
      .liveMeet-table-div{
        width: 100%;
        height: auto;
        margin: auto;
        //display: flex;
        //justify-content: space-around;
        .liveMeet-table-div-div-one{
          width: 23%;
          float: left;
          height: 270px;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #f0f2f5;
          border: 1px dashed #a2a1a1;
          color: #8c8a8a;
          margin-left: 1%;
          margin-right: 1%;
          margin-bottom: 1%;
          .liveMeet-table-div-div-one-font{
          }
        }
        .liveMeet-table-div-div{
          width: 23%;
          min-height: 270px;
          float: left;
          margin-left: 1%;
          margin-right: 1%;
          margin-bottom: 1%;
          background-color: #fff;
          box-shadow: 0px 0px 5px 3px #ececec;
          position: relative;
          .box{
            width: 100%;
            height: 0px;
            position: relative;
            padding-bottom: 56.25%;
            background-color: #f8f7f7;
            //.upDown{
            //  cursor: pointer;
            //  position: relative;
            //  width: 100%;
            //  height:100%;
            //  font-size: 12px;
              //.font{
              //  display: none;
              //  color: #000;
              //  font-size: 20px;
              //  cursor: pointer;
              //}
              .top{
                position: relative;
                //background-color: rgba(0,0,0,0.5);
                width: 100%;
                margin: auto;
                height:30px;
                line-height: 30px;
                color: #fff;
                display: flex;
                //justify-content: space-between;
                padding-left: 20px;
                padding-top: 5px;
                .quSty{
                  width: 50px;
                  height: 30px;
                  line-height: 30px;
                  border: 1px solid #91D5FF;
                  background: #E6F7FF;
                  color: #1890FF;
                  text-align: center;
                  border-radius: 5px;

                }.quStyI{
                   width: 100px;
                   height: 30px;
                   line-height: 30px;
                   border: 1px solid #D3ADF7;
                   background: #F9F0FF;
                   color: #722ED1;
                   text-align: center;
                   border-radius: 5px;
                 }.quStyY{
                    width: 50px;
                    height: 30px;
                    line-height: 30px;
                    border: 1px solid #FFA39E;
                    background: #FEF0EF;
                    color: #F6222D;
                    text-align: center;
                    border-radius: 5px;
                  }.quStyA{
                     width: 50px;
                     height: 30px;
                     line-height: 30px;
                     border: 1px solid #87E8DE;
                     background: #E6FFFB;
                     color: #13C2C2;
                     text-align: center;
                     border-radius: 5px;
                   }.quStyX{
                     width: 70px;
                     height: 30px;
                     line-height: 30px;
                     border: 1px solid #91e887;
                     background: #eff5ee;
                     color: #35c924;
                     text-align: center;
                     border-radius: 5px;
                     margin-left: 10px;
                   }.quStyXH{
                     width: 70px;
                     height: 30px;
                     line-height: 30px;
                     border: 1px solid #444444;
                     background: #e4e5e5;
                     color: #444444;
                     text-align: center;
                     border-radius: 5px;
                     margin-left: 10px;
                   }.quStyD{
                     width: 70px;
                     height: 30px;
                     line-height: 30px;
                     border: 1px solid #FFBB96;
                     background: #FFF2E8;
                     color: #FFBB96;
                     text-align: center;
                     border-radius: 5px;
                     margin-left: 10px;
                   }
              }
              .bottom{
                width: 100%;
                padding-right: 10px;
                position: absolute;
                //text-align: right;
                bottom: 0;
                height:35px;
                line-height: 35px;
                color: #fff;
                background-color: rgba(0,0,0,0.5);
              }
            //}

            //.upDown:hover{
              //background-color: rgba(0,0,0,0.5);
              //.font{
              //  display: block;
              //  font-size:30px;
              //  color: #f1f1f1;
              //  cursor: pointer;
              //}
              //.font:hover{
              //  color: #d2d1d1;
              //}
            //}
            img{
              width: 100%;
              height: 100%;
              position: absolute;
            }
          }
          .content{
            width: 100%;
            .content-title{
              width: 95%;
              height: 30px;
              margin: auto;
              min-height: 50px;
              font-size: 16px;
              color: black ;
              font-weight: bold;
              word-break: break-all;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              /*这里可以设置文本显示的行数*/
              overflow: hidden;
            }
            .content-time{
              width: 95%;
              height: 50px;
              margin: auto;
              display: flex;
              justify-content: space-between;
              color: #8c8a8a;
              font-size: 12px;
              margin-bottom: 5px;
              //position: absolute;
              //bottom: 5px;
              left: 10px;
              //.quSty{
              //  width: 50px;
              //  height: 30px;
              //  line-height: 30px;
              //  border: 1px solid #91D5FF;
              //  background: #E6F7FF;
              //  color: #1890FF;
              //  text-align: center;
              //  border-radius: 5px;
              //}.quStyI{
              //  width: 80px;
              //  height: 30px;
              //  line-height: 30px;
              //  border: 1px solid #D3ADF7;
              //  background: #F9F0FF;
              //  color: #722ED1;
              //  text-align: center;
              //  border-radius: 5px;
              //}.quStyY{
              //  width: 50px;
              //  height: 30px;
              //  line-height: 30px;
              //  border: 1px solid #FFA39E;
              //  background: #FEF0EF;
              //  color: #F6222D;
              //  text-align: center;
              //  border-radius: 5px;
              //}.quStyA{
              //  width: 50px;
              //  height: 30px;
              //  line-height: 30px;
              //  border: 1px solid #87E8DE;
              //  background: #E6FFFB;
              //  color: #13C2C2;
              //  text-align: center;
              //  border-radius: 5px;
              //}
            }
            .content-btn{
              display: flex;
              justify-content: space-around;
              color: #868686;
              font-size: 12px;
              height: 30px;
              line-height: 30px;
              background-color: #f5f5f5;
              border-top: 1px solid #e8e8e8;
              position: absolute;
              bottom: 0px;
              width: 100%;
              .btn-one{
                color: #868686;
                cursor: pointer;
              }
              .btn-one:hover{
                color: #1890ff;
              }

            }
          }
        }
      }
    }
  }
  .uploadImg{
    border: #DDDDDD 2px dashed;
    width: 120px;
    height: 120px;
    background-image: url("../../assets/add.png");
    background-size:100% 100%;
    background-repeat:no-repeat;
    cursor: pointer;
  }
  .upload_image{
    border: #DDDDDD 2px dashed;
    //width: 320px;
    //height: 86.5px;
    width: 220px;
    height: 70px;
    margin-left: 20px;
    text-align: center;
  }
  .upload_deyails{
    border: #DDDDDD 2px dashed;
    width: 240px;
    height: 135px;
    margin-left: 20px;
    text-align: center;
  }
  .upload_poster{
    border: #DDDDDD 2px dashed;
    width: 110px;
    min-height: 120px;
    margin-left: 20px;
    text-align: center;
  }
  .upload_posterH{
    border: #DDDDDD 2px dashed;
    width: 120px;
    height: auto;
    //min-height: 120px;
    margin-left: 20px;
    text-align: center;
  }
  .aleart{
    margin-top: 2px;
    padding-left: 10px;
    height: 32px;
    width: 150px;
    line-height: 32px;
    background-color: #eafbff;
    border-radius: 5px;
    border: 1px solid #3a9af3;
  }
  </style>
